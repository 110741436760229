var setDefaultValues = function(params, confList){
    if (params.price === undefined) {
        params.price = 0;
    }
    if (params.vat === undefined) {
        params.vat = 0;
    }
    if (params.additional_vat === undefined) {
        params.additional_vat = 0;
    }
    if (params.cst_with_cform === undefined) {
        params.cst_with_cform = 0;
    }
    if (params.cst_wo_cform === undefined) {
        params.cst_wo_cform = 0;
    }
    if (params.cenvat === undefined) {
        params.cenvat = 0;
    }
    if (params.edu_cess === undefined) {
        params.edu_cess = 0;
    }
    if (params.hedu_cess === undefined) {
        params.hedu_cess = 0;
    }
    if (params.cgst === undefined) {
        params.cgst = 0;
    }
    if (params.sgst === undefined) {
        params.sgst = 0;
    }
    if (params.igst === undefined) {
        params.igst = 0;
    }
    if (params.cess === undefined) {
        params.cess = 0;
    }
    if (params.taxType === undefined) {
        params.taxType = 'VAT';
    }
    if (params.isGstExempted === undefined) {
        params.isGstExempted = false;
    }
    if (params.salesAbatementPercentage === undefined) {
        params.salesAbatementPercentage = 0;
    }
    if (params.locationStateCode === undefined) {
        params.locationStateCode = 0;
    }
    if (params.customerStateCode === undefined) {
        params.customerStateCode = 0;
    }
    if (confList.Item_Discount_withouttax === undefined) {
        confList.Item_Discount_withouttax = 0;
    }
    if (confList.Bill_Discount_withtax === undefined) {
        confList.Bill_Discount_withtax = 0;
    }
    if (confList.Calculate_Tax_with_Discount === undefined) {
        confList.Calculate_Tax_with_Discount = 0;
    }
    if (confList.Tax_Before_Discount === undefined) {
        confList.Tax_Before_Discount = 0;
    }
    if (params.itemDiscountPercentage === undefined) {
        params.itemDiscountPercentage = 0;
    }
    if (params.itemDiscountAmount === undefined) {
        params.itemDiscountAmount = 0;
    }
    if (params.billDiscountPercentage === undefined) {
        params.billDiscountPercentage = 0;
    }
    if (params.billDiscountAmount === undefined) {
        params.billDiscountAmount = 0;
    }
    if (params.billAmount === undefined) {
        params.billAmount = 1;
    }
    if (params.mrp === undefined) {
        params.mrp = 0;
    }
    if (params.sellingPrice === undefined) {
        params.sellingPrice = 0;
    }
    if (params.roundOffDigit === undefined){
        params.roundOffDigit = 4;
    }
    if (params.itemDiscountAmount > 0) {
        confList.Tax_Before_Discount = 0;
    }
}

var calculateDiscDetails = function(params, confList, netTaxPerc) {

    var netDiscPerc = 0,
        itemDiscountPercentage = 0,
        itemDiscountAmount = 0,
        billDiscountPercentage = 0,
        billDiscountAmount = 0,
        amount = 0,
        goodsAmount = 0,
        grossAmount = 0;
    amount = params.amount;
    if (params.inclusiveTax === 'Y' && (confList.Item_Discount_withouttax === 1 || confList.Tax_Before_Discount === 1)) {
        amount = Math.round(((amount / (100 + netTaxPerc)) * 100) * 100) / 100;
        goodsAmount = amount;
        grossAmount = amount;
    } else {
        goodsAmount = amount;
        grossAmount = amount;
    }
    if (params.itemDiscountPercentage !== 0 || params.itemDiscountAmount === 0) {
        itemDiscountAmount = (Math.round(((amount * params.itemDiscountPercentage) / 100) * 100) / 100);
        itemDiscountPercentage = params.itemDiscountPercentage;
    } else if (params.itemDiscountPercentage === 0 || params.itemDiscountAmount !== 0) {
        itemDiscountPercentage = Math.round(((params.itemDiscountAmount / amount) * 100) * 10000) / 10000;
        itemDiscountAmount = params.itemDiscountAmount;
    }
    if (params.billDiscountPercentage === 0 && params.billDiscountAmount !== 0) {
        billDiscountPercentage = Math.round(((params.billDiscountAmount / params.billAmount) * 100) * 10000) / 10000;
    } else if (params.billDiscountPercentage !== 0 && params.billDiscountAmount === 0) {
        billDiscountPercentage = params.billDiscountPercentage;
    }
    billDiscountAmount = Math.round(((goodsAmount / 100) * billDiscountPercentage) * 10000) / 10000;
    if (confList.Bill_Discount_withtax === 1) {
        netDiscPerc = itemDiscountPercentage;
    } else {
        itemDiscountPercentage = itemDiscountPercentage / 100;
        billDiscountPercentage = billDiscountPercentage / 100;
        netDiscPerc = goodsAmount * itemDiscountPercentage;
        netDiscPerc = netDiscPerc + (goodsAmount - netDiscPerc) * billDiscountPercentage;
        netDiscPerc = (netDiscPerc / goodsAmount) * 100;
        itemDiscountPercentage = itemDiscountPercentage * 100;
        billDiscountPercentage = billDiscountPercentage * 100;
    }
    return {
        itemDiscountPercentage: itemDiscountPercentage,
        itemDiscountAmount: itemDiscountAmount,
        billDiscountPercentage: billDiscountPercentage,
        billDiscountAmount: billDiscountAmount,
        netTaxPerc: netTaxPerc,
        netDiscPerc: netDiscPerc,
        goodsAmount: goodsAmount,
        grossAmount: grossAmount,
        amount: amount,
    };
};



function taxCalcFormula (params) {
    var round =function(value) {
        var roundOffDigit = params.roundOffDigit;
        const rounder = Math.pow(10,roundOffDigit);
        return Math.round(value * rounder) / rounder;
    }
    var amount, tax, vat, additional_vat, cst_with_cform, cst_wo_cform, cenvat, edu_cess, hedu_cess;
    var goodsAmount = 0,
        grossAmount = 0,
        sgst = 0,
        cgst = 0,
        igst = 0,
        saleAbatementAmount = 0,
        cess = 0,
        cgstPercentage = 0,
        sgstPercentage = 0,
        igstPercentage = 0,
        cessPercentage = 0;
    var netTaxPerc = 0,
        billDiscountPercentage = 0,
        billDiscountAmount = 0;
    var taxResult = {};
    var confList = {}; //change
    var itemGross = 0;

    //Set default values for params
    setDefaultValues(params, confList);
    var paramsDetail = {}; //change
    if (params.taxType === 'VAT') {
        netTaxPerc = ((((params.vat + params.cst_with_cform + params.cst_wo_cform) * (100 + params.additional_vat)) / 100) 
            + ((params.cenvat * (100 + params.edu_cess + params.hedu_cess)) / 100));
        params.netTaxPerc = netTaxPerc;
        paramsDetail = calculateDiscDetails(params, confList, netTaxPerc);
        goodsAmount = paramsDetail.goodsAmount;
        grossAmount = paramsDetail.grossAmount;
        amount = params.price;
        if (params.inclusiveTax ==='N' || confList.Item_Discount_withouttax ===1) { /* Basic Amount with Deducted Tax Amount condition && to || */
            // goodsAmount = goodsAmount;
        } else {
            goodsAmount = Math.round(((goodsAmount / (100 + netTaxPerc)) * 100) * 100) / 100;
        }
        itemGross = Math.round(((amount / (100 + netTaxPerc)) * 100) * 100) / 100;
        if (confList.Calculate_Tax_with_Discount ===1) {
            goodsAmount = Math.round(((goodsAmount * (100 - paramsDetail.netDiscPerc)) / 100) * 100) / 100;
        }
        vat = goodsAmount * (params.vat / 100.00);
        cst_with_cform = goodsAmount * (params.cst_with_cform / 100.00);
        cst_wo_cform = goodsAmount * (params.cst_wo_cform / 100.00);
        additional_vat = (vat + cst_with_cform + cst_wo_cform) * (params.additional_vat / 100.00);
        cenvat = goodsAmount * (params.cenvat / 100.00);
        edu_cess = cenvat * (params.edu_cess / 100.00);
        hedu_cess = cenvat * (params.hedu_cess / 100.00);
        tax = vat + cst_with_cform + cst_wo_cform + additional_vat + cenvat + edu_cess + hedu_cess;
        if ((confList.Bill_Discount_withtax === 1 && params.inclusiveTax === 'N') || (params.inclusiveTax === 'Y' 
                    && confList.Item_Discount_withouttax === 1 && confList.Bill_Discount_withtax === 1)) {
            billDiscountAmount = Math.round((((paramsDetail.amount - paramsDetail.itemDiscountAmount + tax) 
                                                    * paramsDetail.billDiscountPercentage) / 100) * 100) / 100;
        } else {
            billDiscountAmount = Math.round((((paramsDetail.amount - paramsDetail.itemDiscountAmount) 
                                                    * paramsDetail.billDiscountPercentage) / 100) * 100) / 100;
        }
        if (paramsDetail.billDiscountPercentage !== 0) {
            billDiscountPercentage = paramsDetail.billDiscountPercentage;
        }
        goodsAmount = Math.round((goodsAmount + paramsDetail.billDiscountAmount) * 10000) / 10000;

        if (params.inclusiveTax ==='Y' && confList.Item_Discount_withouttax ===1) {
            grossAmount = Math.round((grossAmount + tax) * 100) / 100;
        }

        taxResult = {
            tax: tax,
            vat: vat,
            additional_vat: additional_vat,
            cst_with_cform: cst_with_cform,
            cst_wo_cform: cst_wo_cform,
            cenvat: cenvat,
            edu_cess: edu_cess,
            hedu_cess: hedu_cess,
            vatgoodsamount: goodsAmount,
            additional_vatgoodsamount: goodsAmount,
            cst_with_cformgoodsamount: goodsAmount,
            cst_wo_cformgoodsamount: goodsAmount,
            cenvatgoodsamount: goodsAmount,
            edu_cessgoodsamount: cenvat,
            hedu_cessgoodsamount: cenvat,
            itemDiscountAmount: paramsDetail.itemDiscountAmount,
            itemDiscountPercentage: paramsDetail.itemDiscountPercentage,
            billDiscountAmount: billDiscountAmount,
            billDiscountPercentage: billDiscountPercentage,
            amount: grossAmount,
            itemGross: itemGross,
            amountWithDiscount:grossAmount-billDiscountAmount-paramsDetail.itemDiscountAmount
        };
    } else {
        cgstPercentage = params.cgst;
        sgstPercentage = params.sgst;
        igstPercentage = params.igst;
        cessPercentage = params.cess;
        amount = params.price;
        if (params.isGstExempted === true ) {
            cgstPercentage = 0;
            sgstPercentage = 0;
            igstPercentage = 0;
            cessPercentage = 0;
        }
        if ((params.locationStateCode ===0 || params.customerStateCode === 0 || params.locationStateCode === params.customerStateCode) && !params.interState) {
            igstPercentage = 0;
        } else {
            cgstPercentage = 0;
            sgstPercentage = 0;
        }
        netTaxPerc = sgstPercentage + cgstPercentage + igstPercentage + cessPercentage;
        params.netTaxPerc = netTaxPerc;
        paramsDetail = calculateDiscDetails(params, confList, netTaxPerc);
        goodsAmount = paramsDetail.goodsAmount;
        grossAmount = paramsDetail.grossAmount;
        if (params.salesAbatementPercentage > 0 && params.gstCalculationBasedOn !== undefined) {
            if (params.gstCalculationBasedOn === 'MRP') {
                goodsAmount = params.mrp;
            } else if (params.gstCalculationBasedOn === 'SELLING PRICE') {
                goodsAmount = params.sellingPrice;
            }
        }
        saleAbatementAmount = goodsAmount * (params.salesAbatementPercentage / 100);
        if (saleAbatementAmount !== 0) {
            goodsAmount = saleAbatementAmount;
        }

        if (params.inclusiveTax === 'N' || confList.Item_Discount_withouttax === 1 || confList.Tax_Before_Discount === 1) {
            // goodsAmount = goodsAmount;
        } else {
            goodsAmount = Math.round(((goodsAmount / (100 + netTaxPerc)) * 100) * 100) / 100;
        }
        itemGross = Math.round(((amount / (100 + netTaxPerc)) * 100) * 100) / 100;
        if (confList.Calculate_Tax_with_Discount === 1 && saleAbatementAmount === 0) {
            console.log( 'netDisc'+paramsDetail.netDiscPerc);
            goodsAmount = Math.round(((goodsAmount * (100 - paramsDetail.netDiscPerc)) / 100) * 100) / 100;
        }
        igst = round(goodsAmount * (igstPercentage / 100.00));
        sgst = round(goodsAmount * (sgstPercentage / 100.00));
        cgst = round(goodsAmount * (cgstPercentage / 100.00));
        cess = round(goodsAmount * (cessPercentage / 100.00));
        tax = igst + sgst + cgst + cess;

        if ((confList.Bill_Discount_withtax === 1 && params.inclusiveTax === 'N') 
                || (params.inclusiveTax === 'Y' && (confList.Item_Discount_withouttax === 1 || confList.Tax_Before_Discount === 1) 
                && confList.Bill_Discount_withtax === 1)) {
            billDiscountAmount = Math.round((((paramsDetail.amount - paramsDetail.itemDiscountAmount + tax) * paramsDetail.billDiscountPercentage) / 100) * 100) / 100;
        } else {
            billDiscountAmount = Math.round((((paramsDetail.amount - paramsDetail.itemDiscountAmount) * paramsDetail.billDiscountPercentage) / 100) * 100) / 100;

        }
        if (paramsDetail.billDiscountPercentage !== 0) {
            billDiscountPercentage = paramsDetail.billDiscountPercentage;
        }
        goodsAmount = Math.round((goodsAmount + billDiscountAmount) * 10000) / 10000;

        if (params.inclusiveTax === 'Y' && (confList.Item_Discount_withouttax === 1 || confList.Tax_Before_Discount === 1)) {
            grossAmount = Math.round((grossAmount + tax) * 100) / 100;
        }

        taxResult = {
            tax: tax,
            cgst: cgst,
            sgst: sgst,
            igst: igst,
            cess: cess,
            cgstgoodsamount: goodsAmount,
            sgstgoodsamount: goodsAmount,
            igstgoodsamount: goodsAmount,
            cessgoodsamount: goodsAmount,
            saleAbatementAmount: saleAbatementAmount,
            salesAbatementPercentage: params.salesAbatementPercentage,
            itemDiscountAmount: paramsDetail.itemDiscountAmount,
            itemDiscountPercentage: paramsDetail.itemDiscountPercentage,
            billDiscountAmount: billDiscountAmount,
            billDiscountPercentage: billDiscountPercentage,
            amount: grossAmount,
            itemGross: itemGross,
            amountWithDiscount:grossAmount-billDiscountAmount-paramsDetail.itemDiscountAmount,
        };

    }
    return taxResult;
}
